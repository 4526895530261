<template>
  <section class="toggle-button" @click="drawerToggle">
    <div class="line-1 mb-2" :class="`${drawer ? 'clicked' : ''}`"></div>
    <div class="line-2" :class="`${drawer ? 'clicked' : ''}`"></div>
  </section>
</template>

<script>
export default {
  props:['drawer'],
  data() {
    return {
    };
  },
  
  methods: {
    drawerToggle() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  cursor: pointer;
  div {
    width: 35px;
    height: 1px;
    background: white;
  }
  .line-1 {
    transition: all 0.3s ease;
    &.clicked {
      transition: all 0.3s ease;
      transform: rotate(40deg) translateY(5px);
    }
  }
  .line-2 {
    transition: all 0.3s ease;
    &.clicked {
      transition: all 0.3s ease;
      transform: rotate(-40deg) translateY(-5px);
    }
  }
  @media only screen and(max-width: 500px) {
    transform: scale(.8);
    margin-right: 10px;
  }
}
</style>
