<template>
  <div class="auth mt-15">
    <v-form
      @submit.prevent="validate"
      ref="register"
      v-model="valid"
      lazy-validation
    >
      <div class="form-group">
        <label>Name</label>
        <v-text-field
          type="text"
          :rules="rules.name_rules"
          v-model="form.name"
          outlined
          dense
          placeholder="michael olamba"
        >
          <v-icon slot="append"> mdi-account </v-icon></v-text-field
        >
      </div>

      <div class="form-group">
        <label>Email</label>
        <v-text-field
          type="email"
          :rules="rules.email_rules"
          v-model="form.email"
          outlined
          dense
          dark
          placeholder="sample@gmail.com"
        >
          <v-icon slot="append"> mdi-email </v-icon>
        </v-text-field>
      </div>

      <div class="form-group">
        <label>Password</label>
        <v-text-field
          type="password"
          :rules="rules.password_rules"
          v-model="form.password"
          outlined
          dense
          placeholder="Fg432#s$"
        >
        <v-icon slot="append"> mdi-lock </v-icon></v-text-field>
      </div>

      <div class="form-group">
        <label>Confirmation Password</label>
        <v-text-field
          type="password"
          :rules="rules.password_rules.concat(passwordConfirmationRule)"
          v-model="form.password_confirmation"
          outlined
          dense
          placeholder="Fg432#s$"
        >
        <v-icon slot="append">mdi-lock-check</v-icon>
        </v-text-field>
      </div>

      <v-btn
        type="submit"
        class="reg-btn mt-5 w-100"
        :loading="loading"
        :disabled="loading"
      >
        Register
        <v-icon  right>
          mdi-account-plus
        </v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      valid: true,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rules", "wallet_address"]),

    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "Passwords must match";
    },
  },

  methods: {
    ...mapActions(["register"]),

    validate() {
      this.$refs.register.validate();

      this.$nextTick(() => {
        if (this.valid) {
          this.loading = true;
          let content = this.form;
          content.wallet_address = this.wallet_address;

          //
          this.register(content).then((res) => {
            if (res) {
              this.loading = false;
              this.$emit("closeModal");
            } else {
              this.loading = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon{
  color: var(--secondary-color);
}
label{
  font-size: 14px;
    margin-left: 5px;
}
.reg-btn {
  background: var(--third-color) !important;
  color: black;
  font-weight: bolder;
  transition: all 0.2s linear;
  box-shadow: none;
  &:hover {
    letter-spacing: 5px;
    box-shadow: 0 0 12px var(--third-color);
  }
}
</style>
