<template>
  <section class="video-container py-15">
    <h2 class="wrapper-head">Toturial</h2>
    <div class="video-box">
      <video
      @click="togglePlayPause"
        class="video"
        :controls="!isPaused"
        src="https://landsofazolite.com/api/storage/landsofazolite.mp4"
        poster="@/assets/images/video/video-poster.jpg"
      ></video>
      <play-video-button v-if="isPaused" @clicked="togglePlayPause"></play-video-button>
    </div>
  </section>
</template>

<script>
import PlayVideoButton from "./PlayVideoButton.vue";
export default {
  data() {
    return {
      isPaused: true,
    };
  },
  components: {
    PlayVideoButton,
  },
  methods: {
    togglePlayPause() {
      var video = document.querySelector("video");
      console.log(video.paused);
      if (video.paused) {
        this.isPaused = false;
        video.play();
      } else {
        this.isPaused = true;
        video.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  // background: radial-gradient(ellipse at bottom right, #00a2ff76 5%, black 70%);
  background: linear-gradient(to top,#00a2ff76, black );
  .video-box {
    position: relative;
    width: 95%;
    max-width: 1100px;
    border: 2px solid var(--base-color);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .video {
      width: 100%;
    }
  }
}
</style>
