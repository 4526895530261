<template>
  <div class="auth mt-15">
    <v-form
      @submit.prevent="validate"
      ref="login"
      v-model="valid"
      lazy-validation
    >
      <div class="form-group">
        <label>Email</label>
        <v-text-field
          type="email"
          :rules="rules.email_rules"
          v-model="form.email"
          outlined
          dense
          placeholder="sample@gmail.com"
        >
          <v-icon slot="append">mdi-email</v-icon></v-text-field
        >
      </div>

      <div class="form-group">
        <label>Password</label>
        <v-text-field
          :type="`${passwordIsVisible ? 'text' : 'password'}`"
          :rules="rules.password_rules"
          v-model="form.password"
          outlined
          dense
          placeholder="Ss@Fa32$pa"
        >
          <v-icon
            slot="append"
            @click="passwordIsVisible = !passwordIsVisible"
            :color="passwordIsVisible? 'white': ''"
            >{{ passwordIsVisible ? "mdi-eye" : "mdi-eye-off" }}</v-icon
          ></v-text-field
        >
      </div>

      <v-btn
        type="submit"
        class="login-btn mt-5 w-100"
        :loading="loading"
        :disabled="loading"
      >
        Login
        <v-icon small right color="black">mdi-login</v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      valid: true,
      form: {
        email: "",
        password: "",
      },
      passwordIsVisible: false,
    };
  },
  computed: {
    ...mapGetters(["rules", "wallet_address"]),
  },

  methods: {
    ...mapActions(["login"]),

    validate() {
      this.$refs.login.validate();

      this.$nextTick(() => {
        if (this.valid) {
          this.loading = true;
          let content = this.form;
          content.wallet_address = this.wallet_address;

          //
          this.login(content).then((res) => {
            if (res) {
              this.loading = false;
              this.$emit("closeModal");
            } else {
              this.loading = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  .v-icon {
    color: var(--secondary-color);
  }
  label {
    font-size: 14px;
    margin-left: 5px;
  }

  .login-btn {
    background: var(--third-color) !important;
    color: black;
    font-weight: bolder;
    transition: all 0.2s linear;
    box-shadow: none;
    &:hover {
      letter-spacing: 5px;
      box-shadow: 0 0 12px var(--third-color);
    }
  }
}
</style>
