import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
  getUserLands() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/lands`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  transferNftToWallet(_,info) {
    console.log(info);
    return new Promise((resolve, reject) => {
      mainApi
        .post(`users/withdraw-nft`,info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  getUserForSales() {
    return new Promise((resolve, reject) => {
      mainApi
        .get(`/users/sales`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  sellItem(_,info) {
    console.log(info);
    return new Promise((resolve, reject) => {
      mainApi
        .post(`/users/sales`,info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  cancelSell(_,id) {
    return new Promise((resolve, reject) => {
      mainApi
        .delete(`/users/sales/${id}`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },

  
  depositNft(_,info) {
    return new Promise((resolve, reject) => {
      mainApi
        .post(`/users/deposit-nft`,info)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  },
  
};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
