<template>
  <section>
    <div class="land">
      <div class="px-3">
        <section class="d-sm-flex justify-center mt-3">
          <h4 class=" mr-6">
            
            <span class="yellow--text">Lat : </span>{{ map.coordinateX }}
          </h4>
          <h4 class=" mr-6">
            
            <span class="yellow--text">Long : </span>{{ map.coordinateY }}
          </h4>
          <h4 class=" mr-6">
            
            <span class="yellow--text">Width : </span>{{ mapWidth }}
          </h4>
          <h4 class=" mr-6">
            
            <span class="yellow--text">Length : </span>{{ map.height }}
          </h4>
        </section>
        <v-divider class="mb-2"></v-divider>
        <LandMapShow :map="map"></LandMapShow>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["map"],
  computed: {
    mapWidth() {
      if (this.map) {
        return this.map.width;
      } else {
        return "bia";
      }
    },
  },
};
</script>

<style></style>
