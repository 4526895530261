<template>
  <section class="input-pagination">
    <section class="content">
      <button
        class="btn prev"
        :class="prevIsDisable ? 'disabled' : ''"
        @click="prev"
        :disabled="prevIsDisable"
      >
        <v-icon dense> mdi-arrow-left</v-icon>
      </button>
      <span>Page</span>
      <input
        class="input-page"
        type="number"
        min="1"
        max="20"
        v-model="currentPage"
        @input="changePage"
      />
      <span>of</span>
      <span class="font-bold ml-2" >{{ lastPage }}</span>
      <button
        small
        class="btn next"
        :class="nextIsDisable ? 'disabled' : ''"
        @click="next"
        :disabled="nextIsDisable"
      >
        <v-icon dense>mdi-arrow-right</v-icon>
      </button>
    </section>
  </section>
</template>

<script>
export default {
  props: ["total", "page", "lastPage"],
  data() {
    return {
      //currentPage & total & lastPage should come from props
      currentPage: 1,
      // pages: [],
      // firstPages: [],
      // midPages: [],
      // lastPages: [],
    };
  },
  computed: {
    prevIsDisable() {
      return this.currentPage > 1 && this.currentPage <= this.lastPage
        ? false
        : true;
    },
    nextIsDisable() {
      return this.currentPage >= 1 && this.currentPage < this.lastPage
        ? false
        : true;
    },
  },
  created() {
    this.currentPage = this.page;
  },
  methods: {
    next() {
      if (this.currentPage >= 1 && this.currentPage < this.lastPage)
        this.currentPage++;
      this.$emit("passedPage", this.currentPage);
    },
    prev() {
      if (this.currentPage > 1 && this.currentPage <= this.lastPage)
        this.currentPage--;
      this.$emit("passedPage", this.currentPage);
    },
    changePage() {
      if (this.currentPage >= 1 && this.currentPage <= this.lastPage)
        this.$emit("passedPage", this.currentPage);
      else if (this.currentPage > this.lastPage) {
        this.currentPage = this.lastPage;
        this.$emit("passedPage", this.currentPage);
      } else if (this.currentPage < 1) {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-pagination {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: white;
  @media only screen and(max-width: 400px) {
    transform: scale(.8);
    white-space: nowrap;
  }
  .btn {
    padding: 2px 15px;
    background: var(--base-color);
    border-radius: 3px;
    margin: 0 20px;
    transition: all 0.1s ease;
    i {
      color: var(--third-color);
      margin-bottom: 1px;
    }
    &:hover {
      background: var(--secondary-color);
      i {
        color: var(--base-color);
      }
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .input-page {
    margin: 0 5px;
    padding: 3px 5px;
    text-align: center;
    color: var(--secondary-color);
    border: 1px solid var(--base-color);
    border-radius: 3px;
    &:focus {
      outline: none;
      background: none;
      border: 1px solid var(--secondary-color);
    }
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  i {
    cursor: pointer;
  }
}
</style>
