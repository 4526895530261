<template>
  <section class="quastion">
    <v-card class="card" dark>
      <v-card-title class="card-title grey darken-4">
        <section class="w-100 d-flex justify-space-between align-center">
          <h4 class="white--text">Confirmation quastion</h4>
        </section>
      </v-card-title>
      <v-card-text class="py-6 card-text">
        <h4>{{ text }}</h4>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="err" dark @click="cancel"> Cancel </v-btn>
        <v-btn
          class="scc"
          dark
          :loading="isLoading"
          @click="accept"
        >
          Accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
export default {
  props: ["text", "isLoading"],
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    accept() {
      this.$emit("accept");
    },
  },
};
</script>

<style lang="scss" scoped>
.quastion {
  .card {
    background: black;
      .err{
        border: 2px solid #a40000;
        color: #a40000;
        font-weight: 800;
        background: transparent;
        &:hover{
          background: #a40000;
          color: white;
        }
      }
      .scc{
        font-weight: 800;
        background: transparent;
        border: 2px solid green;
        color: green;
        &:hover{
          background: green;
          color: rgb(255, 255, 255);
        }
      }
    
  }
}
</style>
