import mainApi from "../../plugins/axios/axios.js";
const state = {
  token: "",
  user: "",
};

const getters = {
  user: (state) =>
    state.user ? state.user : JSON.parse(localStorage.getItem("user")) || null,
  token: (state) =>
    state.token ? state.token : localStorage.getItem("token") || null,
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
    localStorage.setItem("user", JSON.stringify(payload));
  },

  setToken(state, payload) {
    state.token = payload;
    localStorage.setItem("token", payload);
  },

  userClear(state) {
    state.user = "";
    state.token = "";
    localStorage.clear();
  },
};

const actions = {
  /**
   * User Exist
   * @param {wallet address} payload
   */
  async userExist(_, payload) {
    try {
      const { data } = await mainApi.post("users/auth/check_user", payload);

      //
      let { userExists, twoFactorAuthentication } = data.result;

      //
      return { userExists, twoFactorAuthentication };
    } catch (error) {
      return false;
    }
  },

  /**
   * Register
   */
  async register({ commit }, payload) {
    try {
      const { data } = await mainApi.post("users/auth/register", payload);

      //
      let token = data.data.token;
      let user = data.data;

      commit("setUser", user);
      commit("setToken", token);

      //
      return true;
    } catch (error) {
      return false;
    }
  },

  /**
   * Login
   */
  async login({ commit }, payload) {
    console.log(payload);
    try {
      const { data } = await mainApi.post("users/auth/login", payload);

      //
      let token = data.data.token;
      let user = data.data;

      commit("setUser", user);
      commit("setToken", token);

      //
      return true;
    } catch (error) {
      return false;
    }
  },
  autoSetUser(context) {
    if (localStorage.getItem("user")) {
        context.commit("setUser", JSON.parse(localStorage.getItem("user")));
    }
    if (localStorage.getItem("token")) {
        context.commit("setToken", localStorage.getItem("token"));
    }
},

  setThirdpartyToken({ commit }, token) {
    commit("setToken", token);
  },
  setThirdpartyUser({ commit }, user) {
    console.log(user);
    commit("setUser", user);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
