<template>
  <section class="land-map-show" v-if="map.coordinateXMap">
    <h3 class="map-item-view" @click="handleSelectAndGOToMap">View this area on map <span>➚</span></h3>
    <div class="d-flex flex-column">
      <div class="d-flex">
        <v-img class="map-image" :src="centerItem.path"> </v-img>
        <v-img
          class="map-image"
          :src="mapItems.length ? mapItems[13].path : ''"
        >
        </v-img>
      </div>
      <div class="d-flex">
        <v-img
          class="map-image"
          :src="mapItems.length ? mapItems[17].path : ''"
        >
        </v-img>
        <v-img
          class="map-image"
          :src="mapItems.length ? mapItems[18].path : ''"
        >
        </v-img>
      </div>
    </div>

    <div class="detail-container">
      <div
        class="detail"
        v-for="(item, i) in detailItems"
        :key="i"
        :style="`width:${(400 / 92.38) * 12.45}px;height:${
          (225 / 52.6) * 12.45
        }px;left:${handelX(item)}px;top:${handelY(item)}px;`"
        :class="findOurItem(item) ? 'our-item' : ''"
        
      >
        {{ item.land ? item.land.id : "" }}
        <v-img
          class="details-image"
          :style="item.land ? 'opacity:1' : ''"
          :src="`https://test.landsofazolite.com/api/${item.category.art_portrait}`"
        ></v-img>
        <!-- <v-icon>mdi-information-outline</v-icon> -->
      </div>
    </div>
  </section>
  <section class="no-map d-flex flex-column justify-center align-center" v-else>
    <h3 class="white--text">No map location !</h3>
    <router-link to="/map" v-if="$route.path === '/assets'"
      >Set Location for this land <span>➚</span></router-link
    >
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["map"],
  data() {
    return {
      mapItems: [],
      centerItem: {},
      detailItems: [],
    };
  },
  created() {
    this.fetchMapItems();
    // console.log(this.map.coordinateXMap,this.map.coordinateYMap);
    // console.log(Math.round(this.map.coordinateXMap),Math.round(this.map.coordinateYMap));
    // console.log(parseInt(this.map.coordinateXMap),parseInt(this.map.coordinateYMap));
  },
  methods: {
    ...mapActions(["getMapItems", "getDetails"]),
    fetchMapItems() {
      if (this.map.coordinateXMap) {
        this.getMapItems({
          coordinateX: parseInt(this.map.coordinateXMap),
          coordinateY: parseInt(this.map.coordinateYMap),
          zoom_level: 4,
        }).then((result) => {
          this.mapItems = result.result;
          this.centerItem = this.mapItems[12];
          this.fetchMapDetails();
        });
      }
    },
    fetchMapDetails() {
      this.getDetails({
        start_coordinate_x: this.centerItem.coordinateX,
        start_coordinate_y: this.centerItem.coordinateY,
        end_coordinate_x:
          parseFloat(this.centerItem.coordinateX) +
          parseFloat(this.centerItem.width) +
          20,
        end_coordinate_y:
          parseFloat(this.centerItem.coordinateY) +
          parseFloat(this.centerItem.height) +
          20,
      })
        .then((result) => {
          this.detailItems = result.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handelX(item) {
      return (
        ((parseFloat(item.map.coordinate_x) -
          this.detailItems[0].map.coordinate_x +
          this.findOffsetX()) *
          400) /
        this.centerItem.width
      );
    },
    handelY(item) {
      return (
        ((parseFloat(item.map.coordinate_y) -
          this.detailItems[0].map.coordinate_y +
          this.findOffsetY()) *
          225) /
        this.centerItem.height
      );
    },
    findOffsetX() {
      return (
        parseFloat(this.detailItems[0].map.coordinate_x) -
        parseFloat(this.centerItem.coordinateX)
      );
    },
    findOffsetY() {
      return (
        parseFloat(this.detailItems[0].map.coordinate_y) -
        parseFloat(this.centerItem.coordinateY)
      );
    },
    findOurItem(item) {
      if (item.land) {
        if (item.land.id === this.id) {
          return true;
        }
      }
    },
    handleSelectAndGOToMap() {
      const params = `coordinate_x=${parseInt(
        this.map.coordinateXMap
      )}&coordinate_y=${parseInt(this.map.coordinateYMap)}`;
      this.$router.push(`/map?${params}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.land-map-show {
  position: relative;
  max-height: 340px;
  overflow: hidden;
  transition: filter 0.4s ease;
  &:hover {
    
    &::before {
      background: rgba(5, 5, 5, 0.553);
    }
    .map-item-view {
      cursor: pointer;
      opacity: 1;
      left: 50%;
      top: 50%;
      font-weight: 900;
      
      transform: translate(-50%, -50%) scale(1.4);
    }
  }
  &::before {
    transition: 0.5s ease;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1500;
  }
  .map-item-view {
    transition: 0.8s ease;
    opacity: 0;
    color: rgb(255, 255, 255);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    z-index: 1500;
    &:hover{
      text-decoration: underline;
    }
  }
  .map-image {
    width: 400px;
    height: 225px;
    min-height: 225px;
  }

  .detail-container {
    .detail {
      position: absolute;
      border: 1px solid rgb(0, 0, 0);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transition: all 0.6s ease;
      user-select: none;

      &.our-item {
        z-index: 1000;
        border-color: white;
        overflow: visible;
        transform: scale(1.1);
        &::before {
          z-index: 1001;
          content: "This Item";
          position: absolute;
          top: -40px;
          padding: 2px 10px;
          border-radius: 5px;
          white-space: nowrap;
          background: #2d2d2d;
          animation: shake 0.8s linear infinite;
        }
        &::after {
          animation: shake 0.8s linear infinite;
          z-index: 1001;
          content: "";
          position: absolute;
          top: -15px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 12px solid #2d2d2d;
        }
      }
    }
    .details-image {
      position: absolute;
      top: 0;
      bottom: 0;
      opacity: 0.4;
    }
  }
}
@keyframes shake {
  0% {
    transform: none;
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.no-map {
  width: 95%;
  height: 280px;
  background-image: url("../../assets/images/map-overlay.jpg");
  margin: auto;
  a {
    position: relative;
    text-decoration: underline;
    font-size: 16px;
    margin-top: 5px;
  }
  span {
    position: absolute;
    top: -6px;
    right: -12px;
  }
}
</style>
