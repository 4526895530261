<template>
  <section>
    <h2 class="grey--text text-center mt-15" v-if="!sale">No sale information!</h2>

    <div class="sell" v-else>
      <div class="px-3">
        <div class="d-sm-flex justify-center mt-5">
          <h3 class="yellow--text mr-10">
            Status:
            <span class="grey--text">{{ sale.status }}</span>
          </h3>
          <h3 class="yellow--text mr-4">
            Price:
            <span class="grey--text">{{ sale.price.usdt.price }} $</span>
          </h3>
          <h3 class="yellow--text">
            <span class="red--text" v-html="sale.priceDescription"></span>
          </h3>
        </div>
        <v-divider class="mb-2"></v-divider>
        <h4 class="yellow--text text-center">Transfer List</h4>
        <section class="transfer-list text-center">
          <h5 v-if="sale.transfers_list.length <= 0">No items ...</h5>
          <div v-for="(item, i) in sale.transfers_list" :key="i">
            <span class="mr-1">{{ i }} -</span><span>title</span>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["sale"],
};
</script>

<style></style>
