<template>
  <section>
    <h2 class="text-center mt-15" v-if="!owner">No Owner !</h2>
    <div class="Owner mt-10" v-else>
      <div class="d-sm-flex justify-center px-3">
        <v-img
          src="https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg"
          min-height="100%"
          max-width="120"
          class="mr-5"
        ></v-img>

        <span class="">
          <h2 class="yellow--text mb-2">
            Name:
            <span class="grey--text">{{ owner.name }}</span>
          </h2>
          <h2 class="yellow--text mb-2">
            Email:
            <span class="grey--text">{{ owner.email }}</span>
          </h2>
          <h2 class="yellow--text mb-2">
            ID:
            <span class="grey--text">{{ owner.id }}</span>
          </h2>
          <h2 class="yellow--text wallet-address">
            Wallet:
            <span class="grey--text" style="font-size:14px">{{ owner.wallet_address }}</span>
          </h2>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["owner"],
};
</script>

<style></style>
