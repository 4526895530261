const state = {
  wallet_address: null,
  tronWebStatus: null,
  connectedTo: null,
};

const getters = {
  getTronWebObject(state) {
    return state.tronWebObject;
  },
  tronWebStatus(state) {
    return state.tronWebStatus;
  },
  connectedTo(state) {
    return state.connectedTo;
  },
  wallet_address(state) {
    return state.wallet_address;
  },
};

const mutations = {
  setTronWebStatus(state, payload) {
    state.tronWebStatus = payload;
  },
  setConnectedTo(state, payload) {
    state.connectedTo = payload;
  },
  setWalletAddress(state, payload) {
    state.wallet_address = payload;
  },
  tronBotResetStore(state) {
    state.tronWebObject = {};
    state.isTronAccessible = false;
    state.isTronConnected = "";
    state.defaultAddress = {};
    state.base58 = "";
    state.loginStatus = "";
  },
};

const actions = {
  setTronWebStatus({ commit }, payload) {
    commit("setTronWebStatus", payload);
  },
  setConnectedTo({ commit }, payload) {
    commit("setConnectedTo", payload);
    sessionStorage.setItem("weConnectedTo", payload);
    if (payload === null) {
      sessionStorage.removeItem("weConnectedTo");
    }
  },
  setWalletAddress(context, payload) {
    console.log("this:", payload);
    context.commit("setWalletAddress", payload);
    sessionStorage.setItem("wallet_address", payload);
    if (payload === null) {
      sessionStorage.removeItem("wallet_address");
    }
  },
  
  tronLinkConnect(context) {
    return new Promise((resolve) => {
      if (window.tronWeb !== undefined) {
        window.tronLink.request({
          method: "tron_requestAccounts",
        }).then((result)=>{
          console.log("result:", result);
          if (result.code === 200) {
            context.dispatch("setTronWebStatus", "CONNECTED");
            context.dispatch(
              "setWalletAddress",
              window.tronWeb.defaultAddress.base58
            );
            context.dispatch("setConnectedTo", "TRONLINK");
          } else {
            context.dispatch("setTronWebStatus", "REJECTED");
            if (!window.tronWeb.ready) {
              context.dispatch("setTronWebStatus", "LOCKED");
            }
          }
          resolve()
        })

      } else {
        console.log("install TronWeb");
        context.dispatch("setTronWebStatus", "NOT_INSTALLED");
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
