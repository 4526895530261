<template>
  <section class="sales sec-wrapper">
    <h1 class="wrapper-head text-center">Sales Lands</h1>
    <v-progress-circular
      v-if="loading"
      class="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div class="row mx-auto" v-else>
      <div
        v-for="(item, index) in landsReport"
        :key="index"
        cols="6"
        md="2.5"
        class="item"
      >
        <div class="content">
          <h1>{{ item.totalSales }}</h1>
          <span>of {{ item.totalLands }}</span>
        </div>
        <p class="size">{{ item.title }} Lands</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      landsReport: [],
    };
  },

  methods: {
    ...mapActions(["getLandsReports"]),

    imageSrc(img) {
      return `${process.env.VUE_APP_ROOT_API}/${img}`;
    },

    calcValue(sales, total) {
      return (sales * 100) / total;
    },

    /**
     * Get Lands Report
     */
    async get_lands_report() {
      // start loading
      this.loading = true;

      await this.getLandsReports().then((res) => {
        if (res) {
          console.log(res);
          this.landsReport = res;
        }
      });

      // stop loading
      this.loading = false;
    },
  },

  async mounted() {
    await this.get_lands_report();
    
  },
};
</script>

<style lang="scss" scoped>
.sales {
  background: radial-gradient(ellipse at top left, #00a2ff76 5%, black 70%);
  color: #8d7957;
  .row {
    width: 95%;
    display: grid;
    grid-row-gap: 50px;
    grid-template-columns: repeat(5, 1fr);
  }
  .loading {
    display: block;
    margin: auto;
  }
  .progress-box {
    .title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .item {
    text-align: center;
    margin: 0 auto;
    .content {
      display: flex;
      align-items: baseline;
      justify-content: center;
      h1 {
        font-size: 30px;
        margin-right: 4px;
      }
    }
    &:nth-child(1){
      grid-column: 1;
    }
    &:nth-child(2){
      grid-column: 2;
    }
    &:nth-child(3){
      grid-column: 3;
    }
    &:nth-child(4){
      grid-column: 4;
    }
    &:nth-child(5){
      grid-column: 5;
    }
    &:nth-child(6){
      grid-column: 1;
    }
    &:nth-child(7){
      grid-column: 2;
    }
    &:nth-child(8){
      grid-column: 3;
    }
    &:nth-child(9){
      grid-column: 4;
    }
    &:nth-child(10){
      grid-column: 5;
    }
    @media only screen and (max-width: 960px){
      
  &:nth-child(1){
     grid-column: 2;
    }
  &:nth-child(2){
    grid-column: 4;
    }
    &:nth-child(3){
      grid-column: 1;
    }
    &:nth-child(4){
      grid-column: 3;
    }
    &:nth-child(5){
      grid-column: 5;
    }
    &:nth-child(6){
      grid-column: 2;
    }
    &:nth-child(7){
      grid-column: 4;
    }
    &:nth-child(8){
      grid-column: 1;
    }
    &:nth-child(9){
      grid-column: 3;
    }
    &:nth-child(10){
      grid-column: 5;
    }
  
 }
    @media only screen and (max-width: 550px){
      margin-left: 40%;
   &:nth-child(1){
     grid-column: 2/4;
    }
  &:nth-child(2){
    grid-column: 2/4;
    }
    &:nth-child(3){
      grid-column: 2/4;
    }
    &:nth-child(4){
      grid-column: 2/4;
    }
    &:nth-child(5){
      grid-column: 2/4;
    }
    &:nth-child(6){
      grid-column: 2/4;
    }
    &:nth-child(7){
      grid-column: 2/4;
    }
    &:nth-child(8){
      grid-column: 2/4;
    }
    &:nth-child(9){
      grid-column: 2/4;
    }
    &:nth-child(10){
      grid-column: 2/4;
    }
 }
  }
}
</style>
