<template>
  <section
    class="navbar d-flex justify-space-around justify-sm-space-between align-center px-10"
    :class="`${drawer ? 'drawered' : ''}`"
  >
    <drawer-toggle-button
      class="d-md-none"
      @click="toggleDrawer"
      :drawer="drawer"
    ></drawer-toggle-button>
    <router-link to="/" class="nav-item">
      <v-img
        class="logo"
        src="@/assets/images/Logo_Azollite.png"
        max-width="150px"
      ></v-img>
    </router-link>
    <div class="nav-items d-none d-md-flex">
      <router-link to="/shop" class="nav-item"
        ><a href="">Marketplace</a></router-link
      >
      <router-link to="/map" class="nav-item"><a href="">Map</a></router-link>
      <a
        href="https://doc.landsofazollite.com/"
        target="_blank"
        class="nav-item white--text"
        >White paper</a
      >
      <!-- <router-link to="/journal" class="nav-item"
          ><a href="">Journal</a></router-link
        > -->
      <a href="/#contact" class="nav-item white--text">Contact</a>
    </div>
    <div class="nav-icons">
      <div class="d-none d-md-block">
        <a
          href="https://www.instagram.com/ecosmartecs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon>mdi-instagram</v-icon>
        </a>
        <a
          href="https://t.me/EcoSmartECS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon>mdi-send</v-icon>
        </a>
        <a
          href="https://twitter.com/EcoSmartECS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon>mdi-twitter</v-icon>
        </a>
        <a
          href="https://www.youtube.com/channel/UCZyavwOXisC7aVVYX8FbnPQ/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon>mdi-youtube</v-icon>
        </a>
        <a
          href="https://discord.gg/WJKCk6c"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon>mdi-robot</v-icon>
        </a>
      </div>
      <!-- <v-icon>mdi-cart</v-icon> -->
      <WalletModal />
    </div>
    <nav-drawer-dialog
      v-if="drawer"
      class="d-md-none"
      @click="close"
    ></nav-drawer-dialog>
  </section>
</template>

<script>
import DrawerToggleButton from "./DrawerToggleButton.vue";
import NavDrawerDialog from "./NavDrawerDialog.vue";
import WalletModal from "./WalletModal.vue";
export default {
  data() {
    return {
      drawer: false,
    };
  },
  components: {
    DrawerToggleButton,
    NavDrawerDialog,
    WalletModal,
  },
  mounted() {
    this.navbarAnimation();
  },
  methods: {
    navbarAnimation() {
      const observeObject = document.querySelector(".observer-object");
      const navbar = document.querySelector(".navbar");
      let navObserver = new IntersectionObserver(
        (enteries) => {
          enteries.forEach((entery) => {
            if (entery.isIntersecting) {
              //if object is exsist in view port and threshold is 0
              navbar.classList.remove("watched");
            } else {
              navbar.classList.add("watched");
            }
          });
        },
        { threshold: 0 }
      );
      navObserver.observe(observeObject);
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    close(path) {
      this.drawer = !this.drawer;
      if (this.$route.fullPath === path) {
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 90px;
  background-color: transparent;
  position: fixed !important;
  top: -2px;
  z-index: 120;
  width: 100%;
  transition: all 0.4s ease;
  &.drawered {
    background: #38383b;
  }
  &.watched {
    transition: all 0.4s ease;
    background-color: var(--background-color);
    height: 60px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  .nav-items {
    .nav-item {
      position: relative;
      margin-right: 20px;
      color: var(--text-color) !important;
      a {
        color: var(--text-color);
        font-size: 16px;
      }
      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: left bottom;
        }
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 3px;
        height: 2px;
        width: 100%;
        background: white;
        transform: scaleX(0);
        transition: all 0.3s linear;
      }
      &:active,
      &.router-link-active {
        text-decoration: underline;
        text-shadow: 0 0 15px var(--secondary-color);
        &::after {
          display: none;
        }
      }
    }
  }
  .logo {
    @media only screen and(max-width : 500px) {
      transform: scale(0.7);
    }
  }
  .nav-icons {
    display: flex;
    align-items: center;
    .v-icon {
      color: var(--text-color);
      font-size: 19px;
      margin-right: 26px;
    }
  }
}
</style>
