<template>
  <section class="story text-center mt-15">
    <h2>No Story for now !!!</h2>
  </section>
</template>

<script>
export default {
  props:['selectedLand']
};
</script>

<style></style>
