const state = {
    alert: [],
    alertFlag: false,
};

const getters = {
    // Get Alert
    alert: (state) => state.alert,
    alertFlag: (state) => state.alertFlag,
};

const mutations = {
    // Show Alert
    showAlert(state) {
        return (state.alertFlag = true);
    },

    // Hide Alert
    hideAlert(state) {
        return (state.alertFlag = false);
    },

    // Set Alert
    setAlert(state, alert) {
        state.alert.push(alert);
    },

    // Clear Alert
    clearAlert(state) {
        state.alert = [];
    },
};

const actions = {
    // Set Alert
    setAlert({ commit }, alert) {
        commit("setAlert", alert);
        commit("showAlert");

        setTimeout(() => {
            commit("clearAlert");
            commit("hideAlert");
        }, 4000);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};