const state = {
  rules: {
    email_rules: [
      (v) => !!v || "Please enter your email address",
      (v) => /.+@.+\..+/.test(v) || "Email Address not valid",
    ],
    name_rules: [(v) => !!v || "Please enter your name"],
    message_rules: [(v) => !!v || "Please enter your message"],
    password_rules: [
      (v) => !!v || "Please enter your password",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    password_confirmation_rules: [(v) => !!v || "Please enter your password"],
    amount_rules: [(v) => !!v || "Please enter amount"],
    select: [(v) => !!v || "This Field is required"],
    
  },
};

const getters = {
  rules: (state) => state.rules,
};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
