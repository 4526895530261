import axios from "axios";
import store from "../../store/index.js";
const mainApi = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

mainApi.interceptors.request.use(
    (config) => {
        const token = store.getters.token;
        if (token) {
            config.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
mainApi.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        let status = error.response.status;
        switch (status) {
            //Unauthenticated, token expire or wrong token(کاربر لاگین نیست)
            case 401:
                store.commit("userClear");
                window.location = "/";
                break;
            case 402:
                store.dispatch("changePage", "payment");
                break;
                //Unauthorized (لاگین هست اما دسترسی نداره)
            case 403:
                store.dispatch("setAlert", {
                    title: "Unauthorized",
                    message: "Unauthorized error!! you are not allow to access to this proccess.",
                    type: "error",
                });
                // store.commit("userClear");
                // window.location = "/";
                // store.dispatch("openWalletModal");
                break;

            default:
                break;
        }

        if (error.response.data.result.length && error.response.data.message) {
            for (const [key, value] of Object.entries(error.response.data.result)) {
                let val = value.toString();

                store.dispatch("setAlert", {
                    title: key,
                    message: val,
                    type: "error",
                });
            }
        }

        if (Array.isArray(error.response.data.errors) && error.response.data.errors && error.response.data.message) {
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                let val = value.toString();

                store.dispatch("setAlert", {
                    title: key,
                    message: val,
                    type: "error",
                });
            }
        }

        if (
            error.response.data.message &&
            !Array.isArray(error.response.data.errors) &&
            !error.response.data.result.length
        ) {
            store.dispatch("setAlert", {
                message: error.response.data.message,
                type: "error",
            });
        }

        return Promise.reject(error);
    }
);

export default mainApi;