<template>
  <section class="home">
    <FirstSection />

    <video-player></video-player>
    <Socials />
    <!-- <ImageCarousel />
    <GrowUp /> -->

    <LandsOfAzolite></LandsOfAzolite>
    <!-- <third-section></third-section> -->
    <!-- <Journal /> -->
    <game-mode></game-mode>
    <SalesLands />
    <Contact></Contact>
    <Partnerships />
  </section>
</template>

<script>
import FirstSection from "../components/home/FirstSection.vue";
// import ImageCarousel from "../components/home/ImageCarousel.vue";
import Socials from "../components/home/Socials.vue";
import LandsOfAzolite from "../components/home/LandsOfAzolite.vue";
// import ThirdSection from "../components/home/ThirdSection.vue";
// import Journal from "../components/home/Journal.vue";
// import GrowUp from "../components/home/GrowUp.vue";
import GameMode from "../components/home/GameMode.vue";
import Contact from "../views/Contact.vue";
import VideoPlayer from "../components/home/videoPlayer/VideoPlayer.vue";
import Partnerships from "../components/home/Partnerships.vue";
import SalesLands from "../components/home/SalesLands.vue";

export default {
  name: "Home",

  components: {
    FirstSection,
    LandsOfAzolite,
    // GrowUp,
    // ImageCarousel,
    Socials,
    // ThirdSection,
    // Journal,
    GameMode,
    Contact,
    VideoPlayer,
    Partnerships,
    SalesLands,
  },
};
</script>
<style lang="scss" scoped></style>
