// import store from "../store/index.js";

export default (to, from, next) => {
  if (
    localStorage.getItem("user") != null &&
    localStorage.getItem("user").length > 0 &&
    localStorage.getItem("token") != null &&
    localStorage.getItem("token").length > 0 &&
    sessionStorage.getItem("wallet_address") != null &&
    sessionStorage.getItem("wallet_address").length > 0
  ) {
    // verify with firebase or jwt
    next();
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    sessionStorage.removeItem("wallet_address");
    sessionStorage.removeItem("weConnectedTo");
    next("/");
    // next()
  }
};
