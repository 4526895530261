<template>
  <div>
    <!-- if user connect wallet show dropdown -->
    <v-menu
      v-if="wallet_address && token && user"
      dark
      bottom-right
      transition="scale-transition"
      content-class="profile-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" class="profile-btn">
          <span class="wallet-btn d-none d-lg-block">
            {{ wallet_address }}
          </span>
          <v-icon class="wallet-icon d-block d-lg-none">
            mdi mdi-account
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            @click="$router.push('/profile')"
            class="d-flex align-center"
          >
            <span> Profile </span>
            <v-spacer></v-spacer>
            <v-icon>mdi-account</v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="$router.push('/assets')"
            class="d-flex align-center"
          >
            <span> Assets </span>
            <v-spacer></v-spacer>
            <v-icon>mdi-shopping</v-icon></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="$router.push({ name: 'payment' })"
            class="d-flex align-center"
            ><span> Payments </span>
            <v-spacer></v-spacer>
            <i class="fa-solid fa-credit-card" style="font-size:18px"></i></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="userLogout" class="d-flex align-center"
            ><span> Logout </span>
            <v-spacer></v-spacer>
            <i class="fa-solid fa-right-from-bracket" style="font-size:18px"></i></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- if user dont connected wallet or dont have token show modal -->
    <v-dialog
      v-model="dialog"
      width="500"
      dark
      content-class="wallet-modal"
      v-else
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          class="d-none d-lg-block profile-btn"
        >
          <span class="wallet-btn">
            {{ wallet_address ? wallet_address : "Connect to Wallet" }}
          </span>
        </v-btn>
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          class="d-block d-lg-none profile-btn"
        >
          <v-icon v-bind="attrs" v-on="on" class="wallet-icon">
            mdi mdi-wallet-outline
          </v-icon>
        </v-btn>
      </template>

      <v-card class="card">
        <v-card-title class="card-title d-flex justify-space-between">
          {{
            wallet_address
              ? user_exist
                ? "Login"
                : "Registration"
              : "Connect to Wallet"
          }}

          <v-icon @click="dialog = false">mdi mdi-close-circle-outline</v-icon>
        </v-card-title>

        <v-card-text class="card-text">
          
           <!-- if wallet not installed -->
           
          <!-- <tron-web-not-installed
            v-if="!tronLinkNotInstalled()"
          >{{tronLinkNotInstalled}}</tron-web-not-installed> -->
          <meta-mask-not-installd
            v-if="!metamaskNotInstalled()"
          ></meta-mask-not-installd>
          

          <section v-if="!wallet_address">
            <!-- Metamask Option -->
            <div
              class="option"
              @click="connectToMetaMask"
              v-if="metamaskNotInstalled()"
            >
              <div class="">
                <span v-if="!metaMaskIsLoading">
                  MetaMask Wallet
                <div class="error--text" v-if="metaMaskStatus === 'REJECTED'">
                  You Rejected Try Again!
                </div>
                </span>
                <span v-else class="d-flex justify-canter">
                  <loading-spinner
                   class="spinner"
                  ></loading-spinner>
                </span>
              </div>
              <v-img
                src="https://defi-demo.bluechiz.com/static/media/metamask.02e3ec27.png"
                alt="Meta mask"
                max-width="40px"
              />
            </div>
            <!-- Metamask Option -->
            <!-- TronLink Option -->
            <!-- <div
              class="option"
              @click="connectToTronLink"
              v-if="tronLinkNotInstalled()"
            >
              <div>
                <span v-if="!tronIsLoading" >
                  TronLink Wallet
                <div class="error--text" v-if="tronWebStatus === 'REJECTED'">
                  You Rejected Try Again!
                </div>
                <div class="error--text" v-if="tronWebStatus === 'LOCKED'">
                  Your Wallet Locked, Unlock it!
                </div>
                </span>
                <span v-else class="d-flex justify-canter">
                  <loading-spinner
                  class="spinner"
                  ></loading-spinner>
                </span>
              </div>
              <img
                :src="require('../assets/images/Tronlink.svg')"
                alt="tronlink"
              />
            </div> -->
            <!-- TronLink Option -->
            <div class="text-center mt-8">
              Please Login into your wallet account!
            </div>
          </section>

          <!-- Login Or Register -->
          <section>
            <div class="address" v-if="wallet_address">
              <div class="tip-text">
                Connected with
                {{ connectedTo === "METAMASK" ? "MetaMask" : "TronLink" }}
              </div>
              <div class="address-text">
                <span> {{ wallet_address }} </span>
              </div>
            </div>
            <div
              class="loading"
              style="width: 100%; height: 200px"
              v-if="loading"
            >
              <loading-spinner></loading-spinner>
            </div>
            <div class="auth" v-else-if="wallet_address && !loading">
              <Login v-if="user_exist" />
              <Register v-else />
            </div>
          </section>

         
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Login from "./Login.vue";
import Register from "./Register.vue";
import MetaMaskNotInstalld from "./meta-mask/MetaMaskNotInstalld.vue";
// import TronWebNotInstalled from './tron-web/TronWebNotInstalled.vue';
export default {
  components: {
    Login,
    Register,
    // TronWebNotInstalled,
    MetaMaskNotInstalld,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      user_exist: false,
      // tronIsLoading: false,
      metaMaskIsLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "tronWebStatus",
      "token",
      "user",
      "walletModal",
      "metaMaskStatus",
      "wallet_address",
      "connectedTo",
    ]),
  },
  created() {},

  methods: {
    ...mapActions([
      "userExist",
      "logout",
      "metaMaskConnect",
      "swithToPolygon",
      "tronLinkConnect",
      "setTronWebStatus",
      "setWalletAddress",
      "setConnectedTo",
    ]),
    ...mapMutations(["userClear"]),

    // tronLinkNotInstalled() {
    //   if (window.tronWeb) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    metamaskNotInstalled() {
      if (window.ethereum) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Check User Exist
     */
    async check_user_exist() {
      console.log("user", this.user);
      if (this.dialog && this.wallet_address) {
        this.loading = true;
        let wallet_address = this.wallet_address;
        await this.userExist({ wallet_address }).then((res) => {
          if (res) {
            console.log(res);
            this.user_exist = res.userExists;
          }
        });

        //
        this.loading = false;
      }
    },
    userLogout() {
      this.userClear();
      this.dialog = false;
      if (this.$route.path !== "/") {
        this.$router.replace("/");
      }
    },
    connectToMetaMask() {
      this.metaMaskIsLoading = true;
      this.metaMaskConnect()
        .then(() => {
          this.swithToPolygon();
          this.check_user_exist();
        })
        .finally(() => {
          this.metaMaskIsLoading = false;
        });
    },

    // connectToTronLink() {
    //   this.tronIsLoading = true;
    //   this.tronLinkConnect()
    //     .then(() => {
    //       this.check_user_exist();
    //     })
    //     .finally(() => {
    //       this.tronIsLoading = false;
    //     });
    // },
  },

  watch: {
    // getTronConnectionStatus() {
    //   this.check_user_exist();
    // },

    dialog() {
      // if (this.connectedTo === null) {
      //   this.userClear();
      // }
      this.check_user_exist();
    },
    walletModal() {
      if (this.walletModal) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  &:hover {
    background: var(--base-color);
  }
}
.card {
  border: 2px solid var(--secondary-color);
  .card-title {
    background: var(--base-color);
    font-weight: bolder !important;
  }
  .card-text {
    height: auto !important;
    max-height: 520px;
    overflow: auto;
    background: var(--background-color);
    .logo {
      display: block;
      margin: auto;
      margin-top: 20px;
      width: 200px;
    }
    .option {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      border-radius: 20px;
      padding: 0 30px;
      height: 80px;
      margin-top: 30px;
      cursor: pointer;
      border: 2px solid var(--secondary-color);
      color: var(--secondary-color);
      background: rgba(0, 255, 238, 0.116);
      transition: 0.4s ease;
      
      &:hover {
        background: var(--secondary-color);
        transform: scale(1.03);
        color: black;
        box-shadow: 0 0 10px 2px var(--secondary-color);
      }
      .spinner{
        transform: scale(0.5);
        &::v-deep .circle.circle-1{
          border-top-color:var(--base-color) !important
        }
        &::v-deep .circle.circle-2{
          border-bottom-color:var(--base-color) !important
        }
      }
    }
    .address {
      margin: 0 auto;
      box-shadow: 0 0px 12px 0 var(--secondary-color);
      border-radius: 20px;
      padding: 20px;
      margin-top: 30px;
      background: var(--secondary-color);
      .tip-text {
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 15px;
      }
      .address-text {
        margin-top: 5px;
        color: var(--base-color);
        text-align: center;
        span {
          font-size: 12px;
        }
      }
    }
    .tron-link-tips {
      margin-top: 40px;
      display: block;
    }
  }
}

.profile-menu {
  .v-list {
    // width: 200px;
    .v-list-item {
      min-height: 40px;
      cursor: pointer;
      .v-list-item__title {
        font-size: 12px;
      }
    }
  }
}
.profile-btn {
  background: var(--base-color) !important;
  transition: all 0.3s ease;
  &:hover {
    background: var(--secondary-color) !important;
    box-shadow: 0 0 10px 2px var(--secondary-color);
    color: black;
  }
}

.wallet-modal {
  .wallet-btn {
    width: 146px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
  }
  .wallet-icon {
    color: white !important;
    font-size: 19px !important;
  }
}
</style>
