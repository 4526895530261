<template>
  <v-app>
    <!-- <TronWebComponent /> -->
    <div class="observer-object">.</div>
    <navigation></navigation>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <!-- <theme-toggle-button></theme-toggle-button> -->
    <Footer />

    <!-- snackbar -->
    <div class="alert-box" v-if="alert">
      <v-alert
        v-for="(item, index) in alert"
        :key="index"
        class="response-alert"
        :color="item.type"
        elevation="12"
        dense
        shaped
        dismissible
        :type="item.type"
        transition="scale-transition"
        >{{ item.message }} <span class="v-btn__content"></span
      ></v-alert>
    </div>
    <!-- snackbar -->
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";

import Footer from "./components/Footer.vue";
// import TronWebComponent from "./components/tron-web/TronWebComponent.vue";
// import ThemeToggleButton from "./components/ThemeToggleButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["newPage", "alert"]),
  },
  components: {
    // TronWebComponent,
    Navigation,
    // ThemeToggleButton,
    Footer,
  },
  mounted() {
    
  },
  created() {
    this.autoLogin();
    if (window.ethereum !== undefined) {
      this.isUnlocked();
      this.logoutMetaMask();
    }
    this.autoSetUser();

    //check to tronLink disconnect
    // window.addEventListener("message", (e) => {
    //   if (e.data.message && e.data.message.action === "disconnect") {
    //     this.setTronWebStatus("DISCONNECTED");
    //     this.setWalletAddress(null);
    //     this.setConnectedTo(null);
    //     this.userClear();
    //   }
    // });
  },

  methods: {
    ...mapActions([
      "logoutMetaMask",
      "isUnlocked",
      "autoLogin",
      "setTronWebStatus",
      "setWalletAddress",
      "setConnectedTo",
      "autoSetUser",
    ]),
    ...mapMutations(["userClear"]),
  },
  watch: {
    newPage() {
      if (this.newPage) {
        this.$router.push({ name: this.newPage });
      }
    },
  },
};
</script>
<style lang="scss">
.test {
  color: var(--base-color);
}
.observer-object {
  position: absolute;
  color: transparent;
  top: 0px;
  height: 1px;
  z-index: 5000;
}

.alert-box {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  .response-alert {
    width: max-content;
    margin-bottom: 20px;
    .v-icon.v-icon {
      margin-right: 0px;
      font-size: 15px;
    }
    .v-alert__content {
      font-size: 14px;
    }
  }
}

@media (max-width: 960px) {
  .alert-box {
    right: 12px;
    .response-alert {
      width: max-content;
      margin-bottom: 20px;
      .v-icon.v-icon {
        font-size: 13px;
      }
    }
  }
}
</style>
