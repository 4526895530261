<template>
  <section class="partners sec-wrapper">
    <h1 class="wrapper-head text-center">Our Partners</h1>

    <v-container>
      <v-row class="justify-center">
        <v-col lg="6">
          <v-row class="image-boxes justify-space-between">
            
            <v-col cols="12" sm="4" md="4">
              <a
                href="https://twitter.com/ecosmartecs/status/1542329427417350145?s=21&t=OXLdhVzENDXvrYR5Zmw8aA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  :src="
                    require('../../assets/images/partners/polygon-studios1.png')
                  "
                  alt="poligon-stodio"
              /></a>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <a
                href="https://twitter.com/ecosmartecs/status/1542329427417350145?s=21&t=OXLdhVzENDXvrYR5Zmw8aA"
                target="_blank"
                rel="noopener noreferrer"
                class="d-flex align-center"
              >
                <img
                  :src="
                    require('../../assets/images/partners/eco-smart.png')
                  "
                  alt="Eco-Smart"
              />
              </a>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <a
                href="https://twitter.com/virtualand_tech/status/1538260355289333765?s=21&t=gtjYIFfDEQYPts7m_7GQTg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  :src="require('../../assets/images/partners/virtualand1.png')"
                  alt="virtual-land"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.partners {
  background: radial-gradient(ellipse at top right,#00a2ff76 5%, black 70%);
  color: #8d7957;
}

.image-boxes {
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
