<template>
  <section>
    <div class="tags">
      <section class="px-5">
        <h5 v-if="tags.length <= 0">No items ...</h5>
        <div v-for="(item, i) in tags" :key="i">
          <section class="d-flex  mt-10">
            <h2 class="mr-1 yellow--text">{{ item.title }}</h2>
            <v-spacer></v-spacer>
            <h2 class="mr-5 yellow--text" style="min-width: 80px"
              >Total: <span class="grey--text">{{ item.total }}</span></h2
            >
            <h2 class="mr-1 yellow--text" style="min-width: 80px">
              Sold:
              <span class="grey--text">{{ item.sales }}</span></h2
            >
          </section>
          <v-divider ></v-divider>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  props: ["tags"],
  created() {
    console.log(this.tags);
  },
};
</script>

<style></style>
