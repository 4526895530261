<template>
  <div class="container routes">
    <v-col><h1 class="white--text text-center">Gift Box Opening</h1></v-col>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center">
        <div class="box" :class="isOpen ? 'open' : ''" @click="openGiftBox">
          <div class="box-body" :class="isOpen ? 'open' : ''">
            <section class="rewards-box d-flex ">
              <figure
                class="reward "
                :class="[{'open' : isOpen},rewards.length > 3 ? 'mx-1' : 'mx-auto']"
                v-for="(reward, i) in rewards"
                :key="i"
              >
                {{ reward }}
              </figure>
            </section>

            <div class="box-lid" :class="isOpen ? 'open' : ''">
              <div class="box-bowtie" :class="isOpen ? 'open' : ''"></div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      rewards: ["reward1","reward2","reward2"],
    };
  },
  methods: {
    openGiftBox() {
      if (this.isOpen) {
        return;
      } else {
        this.isOpen = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  position: relative;
  &.open {
    &::before {
      transition: all 1s ease;
      width: 510px;
      height: 510px;
      box-shadow: 0 0 150px rgb(11, 255, 243);
      border: 5px solid rgb(11, 255, 243);
      border-radius: 50%;
    }
  }
}
/* background */
.box::before {
  content: "";
  width: 120px;
  height: 100px;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  box-shadow: 0 0px 180px 100px rgb(11, 255, 243);
  // background: rgba(6, 200, 187, 0.583);
  filter: blur(3px);
  animation: glowing-shadow 3s infinite linear;
}
/* background */

/* box body */
.box-body {
  position: relative;
  height: 300px;
  width: 350px;
  margin-top: 123.3333333333px;
  background-color: #cc231e;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(#762c2c, #ff0303);
  animation: box-shake 3s 3s linear infinite;
}
.box-body:hover {
  cursor: pointer;
  animation: box-shake-hover 0.5s linear infinite;
}

/* box body */

/* reward */
.rewards-box{
  width: 100%;
  overflow: auto;
  transform: translateY(0%) scale(.2);
  opacity: 0;
  transition: all 2s;
}
.box-body .reward {
  min-width: 100px;
  min-height: 100px;
  background: red;
}

.box-body.open .rewards-box {
  opacity: 1;
  transform: translateY(-130px) scale(1);
}
/* reward */

/* white line box-body */
.box-body::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(#ffffff, #ffefa0);
}
/* white line box */

/* box lid */
.box-lid {
  position: absolute;
  z-index: 2;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 90%;
  height: 60px;
  width: 380px;
  background-color: #cc231e;
  border-radius: 5%;
  box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.3);
}
/* box lid */

/* white line box-lid */
.box-lid::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(#ffefa0, #fff);
}
/* white line box-lid */

/* bowtie پاپیون */
.box-bowtie {
  z-index: 2;
  height: 100%;
}
.box-bowtie::before,
.box-bowtie::after {
  content: "";
  width: 83.3333333333px;
  height: 83.3333333333px;
  border: 18.6666666667px solid white;
  border-radius: 50% 50% 0 50%;
  position: absolute;
  bottom: 100%;
  z-index: 0;
}
.box-bowtie::before {
  left: 50%;
  -webkit-transform: translateX(-100%) skew(10deg, 10deg);
  transform: translateX(-100%) skew(10deg, 10deg);
}
.box-bowtie::after {
  left: 50%;
  -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
}
/* bowtie پاپیون */

/* apply animations */
.box-body.open {
  -webkit-animation: box-body 1s forwards ease-in-out;
  animation: box-body 1s forwards ease-in-out;
}
.box-body.open .box-lid {
  -webkit-animation: box-lid 1s forwards ease-in-out;
  animation: box-lid 1s forwards ease-in-out;
}
.box-body.open .box-bowtie::before {
  -webkit-animation: box-bowtie-left 1.1s forwards ease-in-out;
  animation: box-bowtie-left 1.1s forwards ease-in-out;
}
.box-body.open .box-bowtie::after {
  -webkit-animation: box-bowtie-right 1.1s forwards ease-in-out;
  animation: box-bowtie-right 1.1s forwards ease-in-out;
}
/* apply animations */

@keyframes glowing-shadow {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes box-shake {
  0% {
    transform: rotate(0);
  }
  2% {
    transform: rotate(5deg);
  }
  4% {
    transform: rotate(0) scale(1.1);
  }
  6% {
    transform: rotate(-5deg);
  }
  8% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes box-shake-hover {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(0deg) scale(1.1);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes box-lid {
  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%,
  100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}

@keyframes box-lid {
  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%,
  100% {
    -webkit-transform: translate3d(-117%, 225%, 0) rotate(-70deg);
    transform: translate3d(-117%, 225%, 0) rotate(-70deg);
  }
}
@-webkit-keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@-webkit-keyframes box-bowtie-right {
  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@keyframes box-bowtie-right {
  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@-webkit-keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}
@keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}
</style>
