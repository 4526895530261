import mainApi from "../../plugins/axios/axios.js";
const state = {};

const mutations = {};

const actions = {
    getProfileInfo() {
        return new Promise((resolve, reject) => {
            mainApi
                .get(`/users`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject();
                });
        });
    },
    sendEmailVerify() {
        return new Promise((resolve, reject) => {
            mainApi
                .get(`/users/email/resend`)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    console.log(err);
                    reject();
                });
        });
    },
    getEmailVerify(_, info) {
        return new Promise((resolve, reject) => {
            mainApi
                .get(`/users/email/verify`, {
                    params: { hash: info }
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(false);
                    reject();
                });
        });
    },
};
const getters = {};

export default {
    state,
    getters,
    mutations,
    actions,
};